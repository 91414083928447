<template>
    <div class="main">
        <div class="search">
            <el-select v-model="log.byField" size="mini"
                       placeholder="검색조건"
                       style="width:120px;margin-left: 10px">
                <el-option label="전체" :value="null">전체</el-option>
                <el-option label="회원아이디" :value="managerConst.Modified.SEARCH_BY_USERNAME">회원아이디</el-option>
                <el-option label="IP" :value="managerConst.Modified.SEARCH_BY_IP">IP
                </el-option>
            </el-select>
            <el-input type="text" size="mini" v-model="log.searchString" placeholder="아이디/IP"
                      style="width: 150px;margin-left: 5px"></el-input>
            <el-button type="primary" @click="getLogList" size="mini" style="margin-left: 10px">검색</el-button>
            <el-select v-model="log.mtype" @change="getLogList" size="mini" placeholder="로그타입"
                       style="width:110px;margin-left: 5px">
                <el-option label="전체" :value="null">전체</el-option>
                <el-option label="회원정보" :value="managerConst.Modified.MTYPE_USER">회원정보</el-option>
                <el-option label="파트너정보" :value="managerConst.Modified.MTYPE_PARTNER">파트너정보</el-option>
                <el-option label="게임정보" :value="managerConst.Modified.MTYPE_GAME">게임정보</el-option>
                <el-option label="IP정보" :value="managerConst.Modified.MTYPE_IP">IP정보</el-option>
                <el-option label="관리자비번" :value="managerConst.Modified.MTYPE_MANAGERPWD">관리자비번</el-option>
                <el-option label="사이트정보" :value="managerConst.Modified.MTYPE_SITE">사이트정보</el-option>
            </el-select>

            <el-button type="warning" @click="updateAllLog" size="mini" style="margin-left: 10px">전체확인</el-button>
        </div>

        <div class="data">
            <el-table
                    :data="logList"
                    style="width: 100%"
                    max-height="730"
                    border>
                <el-table-column
                        label="날짜"
                        width="130">
                    <template slot-scope="scope">
                        {{scope.row.createTime|datef('MM-DD HH:mm:ss')}}
                    </template>
                </el-table-column>

                <el-table-column
                        label="IP"
                        width="300">
                    <template slot-scope="scope">
                        {{scope.row.ip}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="처리자"
                        width="100">
                    <template slot-scope="scope">
                        {{scope.row.operator}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="회원정보"
                        width="150">
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" @click="editUser(scope.row.userId,managerConst.MODE_EDIT)" v-if="scope.row.user">
                            {{scope.row.user.nickname}}</el-button>

                        <el-button size="mini" type="primary" @click="editPartner(scope.row.partnerId,managerConst.MODE_EDIT)" v-if="scope.row.partner">
                            {{scope.row.partner.nickname}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column
                        label="변경내용"
                        width="550">
                    <template slot-scope="scope">
                        <div style="width: 100%;overflow-x: scroll;" v-html="scope.row.contentBefor"></div>
                        <div v-html="scope.row.contentAfter"></div>
                    </template>
                </el-table-column>
                <el-table-column
                        label="상태"
                        width="60">
                    <template slot-scope="scope">
                               <span v-if="scope.row.checked == managerConst.UNCHECKED"
                                     class="badge badge-danger">
                                     미확인
                                </span>

                    </template>
                </el-table-column>

            </el-table>
        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[25, 50, 100, 150, 200]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>

</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {updateGroup} from "../../network/manager/groupRequest";
    import {manager} from "../../common/administrator/managerMixin";
    import managerConst from "../../common/administrator/managerConst";
    import {getLogCashPointList} from "../../network/manager/logCashPointRequest";
    import {getModifiedHistoryList, updateAllModifiedHistory} from "../../network/manager/logModifyRequest";
    import Vue from "vue";
    import UserEditToast from "../../components/administrator/UserPanel/UserEditToast";
    import PartnerEditToast from "../../components/administrator/UserPanel/PartnerEditToast";
    import {Loading} from "element-ui";
    Vue.prototype.$userEditToast = UserEditToast
    Vue.prototype.$partnerEditToast = PartnerEditToast
    export default {
        name: "ManagerModifiedHistory",
        mixins: [manager],
        components: {ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        data() {
            return {
                startDate: '',
                endDate: '',
                pageNum: 1,
                pageSize: 25,
                pageTotal: 0,
                log: {},
                logList: [],

            }
        },
        methods: {
            updateAllLog(){
              updateAllModifiedHistory({checked:managerConst.CHECKED}).then(res=>{
                  this.getLogList()
              })
            },
            editPartner(id, mode) {
                this.$partnerEditToast({'partnerId': id, 'mode': mode})
            },
            editUser(userId, mode) {
                this.$userEditToast({'userId': userId, 'mode': mode})
            },
            getLogList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                this.log.startDate = this.startDate;
                this.log.endDate = this.endDate;
                getModifiedHistoryList(this.log, this.pageNum, this.pageSize).then(res => {
                    this.logList = res.data.data
                    this.pageTotal = res.data.total
                    loadingInstance.close()
                })
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getLogList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getLogList();
            },
            setStartDate() {
                this.startDate = this.$moment(this.startDate).format('yyyy-MM-DD');
            },
            setEndDate() {
                this.endDate = this.$moment(this.endDate).format('yyyy-MM-DD')
            },
        },
        created() {
            this.log.userOrPartner = this.$route.query.userOrPartner;
            this.startDate = this.$moment().subtract(7, 'days').format('yyyy-MM-DD')
            this.endDate = this.$moment().format('yyyy-MM-DD')
            this.getLogList()
        },
    }
</script>

<style scoped>

</style>